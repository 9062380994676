.hidden {
  display: none;
}

.form-title-wrapper {
  display: flex;
  align-items: center;
}

.form-item {
  margin-left: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.form-item-title {
  padding-left: 0.25rem;
  margin: 1rem 0;
}
