.dropdown-wrapper {
  border-bottom: solid 0.5px rgba(0,0,0,0.5);
  border-radius: 0.15rem;
}

.dropdown-container {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  margin: 1rem 0;
}

.dropdown-container > * {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.title-wrapper {
}

.visibility-toggle {
  margin-right: 0.5rem;
  cursor: pointer;
}

.dropdown-toggle {
  cursor: pointer;
  font-size: 2.5rem;
  line-height: 0.9;
  padding-bottom: 0.25rem;
}
