.preview {
	width: 602px;
	min-height: 100px;
	max-height: 90vh;
	border: solid 1px rgba(0, 0, 0, 0.25);
	border-radius: 0.15rem;
	overflow: auto;
}

.preview-container {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
