.error-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-block {
  border: solid 0.5px rgba(0,0,0,0.25);
  border-radius: 0.25rem;
  padding: 1rem;
  font-size: 1.25rem;
}

.error-block button {
  cursor: pointer;
}
