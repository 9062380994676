.hidden {
  display: none;
}

.modal-wrapper {
  flex-basis: 100%;
  margin-bottom: 1rem;
}

.modal-wrapper button,
.text-field-btn {
  cursor: pointer;
}

.modal-wrapper button {
  border: solid 0.5px rgba(0,0,0,0.25);
  border-radius: 0.25rem;
}

/* .modal {
  border: solid 0.5px rgba(0,0,0,0.05);
  border-radius: 0.25em;
  display: flex;
  justify-content: space-between;
} */

.modal textarea {
  flex-grow: 1;
  margin: 0 1.25rem;
}

.modal button {
  /* margin: 2.5rem;
  font-size: 1rem; */
}

.close-modal {
  position: absolute;
  top: 0;
  right: 2.5%;
  cursor: pointer;
}
