.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn {
  /* border: 0.5px solid black;
  background-color: white;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold; */
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
